/*GLOBAL VARIABLES*/
:root {
    --mainLight: #def4f5;
    --main: #00d9e2;
    --mainHover: #00c4cc;
    --error: #ef593f;
    --success: #18c60d;
    --gray: #f8f8f8;
    --middleGray: #e1e1e1;
    --middleDarkGray: #979797;
    --darkGray: #4f4f4f;
}

/*SETTINGS*/
@import "./settings/reset.css"; /*A modern alternative to CSS resets*/

/*COMPONENTS*/
@import "./components/rangeslider.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./components/slider.css";
