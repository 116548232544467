/* ==========================================================================
   #SLIDER
   ========================================================================== */

/**
 * Custom style for `slick-carousel` component
 */


/*Intro slider*/
.slick-slider {
  height: 100%;
}

.slick-dots {
  bottom: 0;
}
.slick-dots li button{
  pointer-events: none;
}

.slick-dots li button:before {
  font-size: 16px;
  color: var(--middleGray);
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.slick-dots li.slick-active button:before {
  color: var(--main);
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  color: var(--main);
}

.slick-slide--single {
  outline: none;
}

/*Business photos slider*/
.c-slick--photos{
    margin: 12px 0;
}
.c-slick--photos .slick-arrow {
    z-index: 9;
}
.c-slick--photos .slick-prev {
  left: 6px;
}
.c-slick--photos .slick-next {
  right: 6px;
}
.c-slick--photos .slick-list {
  height: 180px;
}
.c-slick--photos .slick-slide {
  height: 180px;
  width: auto;
  padding: 0 1px;
}

