/* ==========================================================================
   #RANGE SLIDER
   ========================================================================== */

/**
 * Custom style for `rc-slider` component
 */

.c-rc-slider {
  width: 90% !important;
  margin: 0 auto;
}

.c-rc-slider .rc-slider-rail {
  height: 10px;
  background: var(--middleDarkGray);
}

.c-rc-slider .rc-slider-track {
  height: 10px;
  background-color:  var(--main);
}

.c-rc-slider .rc-slider-handle {
  width: 30px;
  height: 30px;
  margin-top: -10px;
  background: var(--main);
  box-shadow: 0 1px 4px rgba(0, 0, 0, .5) !important;
  border: none !important;
  outline: none;
}

.c-rc-slider.c-value {
  margin: 24px auto;
}
